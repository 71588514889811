import { jsx as _jsx } from "react/jsx-runtime";
import { m } from 'framer-motion';
// @mui
import { Box } from '@mui/material';
//
import { varFade } from './variants';
export default function TextAnimate({ text, variants, sx, ...other }) {
    return (_jsx(Box, { component: m.div, sx: {
            m: 0,
            typography: 'h1',
            overflow: 'hidden',
            display: 'inline-flex',
            ...sx,
        }, ...other, children: text.split('').map((letter, index) => (_jsx(m.span, { variants: variants || varFade().inUp, children: letter }, index))) }));
}

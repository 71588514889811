import { jsx as _jsx } from "react/jsx-runtime";
import { m } from 'framer-motion';
// @mui
import { Box } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
//
import { varContainer } from './variants';
export default function MotionViewport({ children, disableAnimatedMobile = true, ...other }) {
    const isMobile = useResponsive('down', 'sm');
    if (isMobile && disableAnimatedMobile) {
        return _jsx(Box, { ...other, children: children });
    }
    return (_jsx(Box, { component: m.div, initial: "initial", whileInView: "animate", viewport: { once: true, amount: 0.3 }, variants: varContainer(), ...other, children: children }));
}
